const invitation = {
    find_your_tenants: '尋找你的承租人',
    find_tenants_description: '您的電子郵件地址可能已在多個租戶中註冊。您可以選擇加入現有租戶或繼續創建新的。',
    create_new_tenant: '建立新租戶',
    email_not_match_title: '您目前是以\n{{email}}\n登入',
    email_not_match_description: '請使用正確的帳戶登入以接受邀請並成為組織的成員。',
    switch_account: '切換帳號',
    invalid_invitation_status: '無效的邀請。請聯絡管理員並重試。',
    invitation_not_found: '找不到邀請。請聯絡管理員。',
};
export default Object.freeze(invitation);
