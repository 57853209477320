const enterprise_sso_details = {
    back_to_sso_connectors: '返回企業SSO',
    page_title: '企業SSO連接器詳細信息',
    readme_drawer_title: '企業SSO',
    readme_drawer_subtitle: '設置企業SSO連接器以啟用終端用戶SSO',
    tab_experience: 'SSO體驗',
    tab_connection: '連接',
    general_settings_title: '一般',
    custom_branding_title: '顯示',
    custom_branding_description: '自定義在終端用戶單點登錄流程中顯示的名稱和圖標。 當為空時，將使用默認值。',
    email_domain_field_name: '企業郵箱域',
    email_domain_field_description: '使用此郵箱域的用戶可以使用SSO進行身份驗證。 請驗證該域是否屬於企業。',
    email_domain_field_placeholder: '郵箱域',
    sync_profile_field_name: '將配置文件信息從身份提供者同步',
    sync_profile_option: {
        register_only: '僅在首次登錄時同步',
        each_sign_in: '每次登錄時始終同步',
    },
    connector_name_field_name: '連接器名稱',
    display_name_field_name: '顯示名稱',
    connector_logo_field_name: '顯示圖標',
    connector_logo_field_description: '每個圖像應小於500KB，僅支援SVG，PNG，JPG，JPEG。',
    branding_logo_context: '上傳圖標',
    branding_logo_error: '上傳圖標錯誤：{{error}}',
    branding_light_logo_context: '上傳淺色模式圖標',
    branding_light_logo_error: '上傳淺色模式圖標錯誤：{{error}}',
    branding_logo_field_name: '圖標',
    branding_logo_field_placeholder: 'https://your.domain/logo.png',
    branding_dark_logo_context: '上傳深色模式圖標',
    branding_dark_logo_error: '上傳深色模式圖標錯誤：{{error}}',
    branding_dark_logo_field_name: '圖標（深色模式）',
    branding_dark_logo_field_placeholder: 'https://your.domain/dark-mode-logo.png',
    check_connection_guide: '連接指南',
    enterprise_sso_deleted: '企業SSO連接器已成功刪除',
    delete_confirm_modal_title: '刪除企業SSO連接器',
    delete_confirm_modal_content: '您確定要刪除此企業連接器嗎？ 身份提供者的用戶將無法使用單點登錄。',
    upload_idp_metadata_title_saml: '上傳元數據',
    upload_idp_metadata_description_saml: '配置從身份提供者複製的元數據。',
    upload_idp_metadata_title_oidc: '上傳憑證',
    upload_idp_metadata_description_oidc: '配置從身份提供者複製的憑證和OIDC令牌信息。',
    upload_idp_metadata_button_text: '上傳元數據XML文件',
    upload_signing_certificate_button_text: '上傳簽名憑證文件',
    configure_domain_field_info_text: '添加電子郵件域以引導企業用戶到其身份提供者進行單點登錄。',
    email_domain_field_required: '需要填寫電子郵件域以啟用企業SSO。',
    upload_saml_idp_metadata_info_text_url: '粘貼來自身份提供者的元數據URL以進行連接。',
    upload_saml_idp_metadata_info_text_xml: '粘貼來自身份提供者的元數據以進行連接。',
    upload_saml_idp_metadata_info_text_manual: '填寫來自身份提供者的元數據以進行連接。',
    upload_oidc_idp_info_text: '填寫來自身份提供者的信息以進行連接。',
    service_provider_property_title: '在IdP中配置',
    service_provider_property_description: '使用{{protocol}}在身份提供者中設置應用程序集成。 輸入Logto提供的詳細信息。',
    attribute_mapping_title: '屬性映射',
    attribute_mapping_description: '通過在身份提供者或Logto端配置用戶屬性映射來從身份提供者同步用戶配置文件。',
    saml_preview: {
        sign_on_url: '登錄URL',
        entity_id: '發行者',
        x509_certificate: '簽名憑證',
        certificate_content: '到期{{date}}',
    },
    oidc_preview: {
        authorization_endpoint: '授權端點',
        token_endpoint: '令牌端點',
        userinfo_endpoint: '用戶信息端點',
        jwks_uri: 'JSON Web鍵集端點',
        issuer: '發行者',
    },
};
export default Object.freeze(enterprise_sso_details);
