const logs = {
    page_title: '審計日誌',
    title: '審計日誌',
    subtitle: '查看用戶行為和事件',
    event: '事件',
    user: '用戶',
    application: '應用',
    time: '時間',
    filter_by: '過濾',
};
export default Object.freeze(logs);
