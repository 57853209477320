const contact = {
    title: '獲得幫助',
    description: '欲查詢幫助或提供產品反饋，請透過以下方式聯絡我們。',
    discord: {
        title: '加入 Discord 社群',
        description: '與其他開發人員交流，尋找解決方案',
        button: '加入',
    },
    github: {
        title: '在 GitHub 上溝通',
        description: '創建問題並提交到 GitHub',
        button: '打開',
    },
    email: {
        title: '透過電子郵件聯絡客服支援',
        description: '發送電子郵件以獲取進一步信息和幫助',
        button: '發送',
    },
    reserve: {
        title: '與 Logto 團隊預定時間',
        description: '快速預定一個會話內容',
        button: '預定',
    },
};
export default Object.freeze(contact);
