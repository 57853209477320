const session = {
    not_found: '未找到會話。請返回並重新登錄。',
    invalid_credentials: '賬號或密碼錯誤，請重新輸入。',
    invalid_sign_in_method: '當前登錄方式不可用',
    invalid_connector_id: '找不到 ID 為 {{connectorId}} 的可用連接器。',
    insufficient_info: '登錄信息缺失，請檢查你的輸入。',
    connector_id_mismatch: '傳入的連接器 ID 與 session 中保存的記錄不一致',
    connector_session_not_found: '無法找到連接器登錄信息，請嘗試重新登錄。',
    verification_session_not_found: '驗證失敗，請重新驗證。',
    verification_expired: '當前頁面已超時。為確保你的賬號安全，請重新驗證。',
    verification_blocked_too_many_attempts: '短時間內嘗試次數太多。請過一會再試 {{relativeTime}}。',
    unauthorized: '請先登錄',
    unsupported_prompt_name: '不支持的 prompt name',
    forgot_password_not_enabled: '忘記密碼功能沒有開啟。',
    verification_failed: '驗證失敗，請重新驗證。',
    connector_validation_session_not_found: '找不到連接器用於驗證 token 的信息。',
    csrf_token_mismatch: 'CSRF token 不匹配。',
    identifier_not_found: '找不到用戶標識符。請返回並重新登錄。',
    interaction_not_found: '找不到互動會話。請返回並重新開始會話。',
    not_supported_for_forgot_password: '此操作不支援忘記密碼。',
    identity_conflict: '偵測到身份不匹配。請啟動一個新的會話以使用不同的身份繼續。',
    mfa: {
        require_mfa_verification: '需要 MFA 驗證才能登錄。',
        mfa_sign_in_only: 'MFA 只能用於登錄互動。',
        pending_info_not_found: '未找到等待中的 MFA 資訊，請先啟動 MFA。',
        invalid_totp_code: '無效的 TOTP 驗證碼。',
        webauthn_verification_failed: 'WebAuthn 驗證失敗。',
        webauthn_verification_not_found: '未找到 WebAuthn 驗證。',
        bind_mfa_existed: 'MFA 已存在。',
        backup_code_can_not_be_alone: '備用碼不能作為唯一 MFA。',
        backup_code_required: '備用碼是必需的。',
        invalid_backup_code: '無效的備用碼。',
        mfa_policy_not_user_controlled: 'MFA 政策不受使用者控制。',
    },
    sso_enabled: '該郵箱已開啟單點登錄，請使用 SSO 登錄。',
};
export default Object.freeze(session);
